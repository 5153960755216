/* eslint-disable */
import { useCallback, useEffect, useState } from 'react'

import { UilExclamationTriangle, UilArrowLeft, UilArrowRight, UilArrowDown, UilArrowUp } from '@iconscout/react-unicons'
import { CircularProgress, Collapse } from '@mui/material'

import { useSnackbar } from '~/_context/Snackbar'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { useServiceManageStudents } from '~/services/ManageStudents'

import { IconComponent, ModalComponent, ModalPlaneComponent } from '~/components'

import { AvatarStudentComponent, CardStudentComponent } from '~/containers/private/Fund1/LessonAssessment'

import { AlertAssessment } from '../../Forms/Assessment/Assessment.styles'
import { IModalStudentManagementComponentProps } from './ModalStudentManagement.interfaces'
import * as S from './ModalStudentManagement.styles'
import { Group, MomentStageSubGroup } from '~/services/Students/types'
import { useDrop } from 'react-dnd'
import CustomDragLayer from './CustomDragLayer'
import { useServiceGetStudentsGroupsPdf } from '~/services/Files'

interface StepData {
  icon: string
  title: string
  description: string
  totalStudents: number
  idMomentStep: number
  stageGroups?: MomentStageSubGroup
}

export interface Student {
  idStudent: number
  idSchool: number
  coGrade: string
  Group: unknown
  idUserStudent: number
  isSelected: boolean
  nuSubGroup: boolean
  idProjectMomentGroup: number
  idMomentStep: number
  inAttendance: boolean
  idNetwork: number
  txName: string
  txImagePath?: string
}
export interface StudentDetails {
  map(
    arg0: (student: Student) => {
      idMomentStep: number
      inAttendance: boolean
      idMomentGroup: number
      idUserStudent: number
      nuSubGroup: boolean
    },
  ): unknown
  idMomentStep: number
  inAttendance: boolean
  idMomentGroup: number
  idUserStudent: number
  nuSubGroup: boolean
}

interface SubGroup {
  nuSubGroup(selectedStudents: SelectedStudents, idUserStudent: any, idMomentStep: number, nuSubGroup: any): unknown
  currentIndex: number
  group: Student[]
}

interface StudentsGroupContainerProps {
  sub: SubGroup
  stepIndex: number
  step: StepData
}

type SelectedStudents = Student[]

interface DraggedItem {
  object: {
    idProjectMomentStage: number
    inAttendance: boolean
    idUserStudent: number
    nuSubGroup: boolean
  }
}

export const ModalStudentManagementComponent = ({
  openModal,
  actionCloseModal,
  idLessonMoment,
  classID,
  dataClass,
  dataStudentsGroup,
  refetchStudents,
}: IModalStudentManagementComponentProps) => {
  const { width } = useWindowDimensions()
  const [draggedStudentId, setDraggedStudentId] = useState<number | null>(0)
  const [totalStudents, setTotalStudents] = useState(0)
  const isTablet = width && width < 1024
  const maxLengthDisplay = isTablet ? 3 : 5
  const { showSnackbarError } = useSnackbar()
  const [countStudents, setCountStudents] = useState(0)
  const [showPlanModal, setShowPlanModal] = useState(false)

  const {
    data: studentsGroupPdfData,
    mutate: handleDownload,
    isLoading,
  } = useServiceGetStudentsGroupsPdf(classID, idLessonMoment)

  useEffect(() => {
    if (!isLoading && studentsGroupPdfData) {
      DownloadPdf()
    }
  }, [studentsGroupPdfData, isLoading])

  const DownloadPdf = () => {
    const blob = new Blob([studentsGroupPdfData], { type: 'application/pdf' })
    const url = (window.webkitURL || window.URL).createObjectURL(blob)
    let link = document.createElement('a')
    link.href = url
    link.download = `grupos-lekto.pdf`
    link.click()
    setTimeout(function () {
      ;(window.webkitURL || window.URL).revokeObjectURL(url)
    }, 100)
  }

  const calculateTotalStudents = () => {
    setSteps((prevSteps) => {
      let total = 0
      prevSteps.forEach((step) => {
        step?.stageGroups?.subGroups.forEach((subGroup) => {
          total += subGroup.group.length
        })
      })
      setTotalStudents(total)
      return prevSteps
    })
  }

  const handleCloseModal = () => {
    setShowPlanModal(false)
  }

  useEffect(() => {
    calculateTotalStudents()
  }, [])

  const {
    isLoading: isLoadingManageStudents,
    error: errorManageStudents,
    data: dataManageStudents,
    mutate: handleManageStudents,
  } = useServiceManageStudents()

  useEffect(() => {
    if (!isLoadingManageStudents && errorManageStudents) {
      showSnackbarError(
        errorManageStudents?.response?.data?.message?.length
          ? errorManageStudents?.response?.data?.message
          : errorManageStudents?.response?.data?.title,
      )
    }
  }, [errorManageStudents, isLoadingManageStudents, showSnackbarError])

  useEffect(() => {
    if (!isLoadingManageStudents && dataManageStudents) {
      refetchStudents().then()
    }
  }, [errorManageStudents, isLoadingManageStudents])

  const [selectedStudents, setSelectedStudents] = useState<SelectedStudents>([])

  const findAllSelectedStudents = () => {
    let selectedStudents: Student[] = []

    for (const step of steps) {
      const subGroups = step?.stageGroups?.subGroups || []

      for (const subGroup of subGroups) {
        const selected = subGroup.group.filter((student: Student) => student.isSelected)
        selectedStudents = selectedStudents.concat(selected)
      }
    }

    setSelectedStudents(selectedStudents)
    setCountStudents(selectedStudents.length)
  }

  const [studentsNotInAttendance, setStudentsNotInAttendance] = useState<Student[]>([])

  const findAllStudentsNotInAttendance = () => {
    let studentsNotInAttendance: Student[] = []

    for (const step of steps) {
      const subGroups = step?.stageGroups?.subGroups || []
      for (const subGroup of subGroups) {
        const notInAttendance = subGroup.group.filter((student: Student) => !student.inAttendance)
        studentsNotInAttendance = studentsNotInAttendance.concat(notInAttendance)
      }
    }

    setStudentsNotInAttendance(studentsNotInAttendance)
  }

  function sendUpdateRequest(movedStudents: StudentDetails) {
    const itemArray = movedStudents.map((student: Student) => ({
      idMomentStep: student.idMomentStep,
      inAttendance: student.inAttendance,
      idMomentGroup: student.idProjectMomentGroup,
      idUserStudent: student.idUserStudent,
      nuSubGroup: student.nuSubGroup,
    }))

    handleManageStudents({
      idClass: classID,
      idLessonMoment: idLessonMoment,
      body: itemArray,
    })
  }

  const handleSelectStudent = (idStudent: number, step: StepData) => {
    setSteps((prevSteps) => {
      const newSteps = [...prevSteps]
      const subGroups = step?.stageGroups?.subGroups

      if (subGroups) {
        for (let subGroup of subGroups) {
          if (subGroup.group.length > 1) {
            for (let student of subGroup.group) {
              if (student.idStudent === idStudent) {
                student.isSelected = !student.isSelected
                break
              }
            }
          }
        }
      }

      return newSteps
    })
  }

  const buildSteps = useCallback((): StepData[] => {
    const steps: StepData[] = []

    for (let i = 0; i < 3; i++) {
      const icon =
        dataClass?.lesson?.lessonStep[1]?.lessonActivity?.[i]?.category?.txImagePath ||
        (dataClass.projectMomentStage?.[i]?.projectStage?.project?.projectCategory?.[0]?.category
          ?.txImagePath as string)

      const title =
        dataClass?.lesson?.lessonStep[1]?.lessonActivity?.[i]?.category?.txName ||
        (dataClass.projectMomentStage?.[i]?.projectStage?.project?.projectCategory?.[0]?.category?.txName as string)

      const description =
        dataClass?.lesson?.lessonStep[1]?.lessonActivity?.[i]?.txTitle ||
        (dataClass.projectMomentStage?.[i]?.projectStage?.project?.txTitle as string)

      const idMomentStep =
        dataClass?.lesson?.lessonStep[1]?.lessonActivity?.[i]?.lessonMomentActivity[0]?.idLessonMomentActivity ||
        dataClass.projectMomentStage?.[i]?.idProjectMomentStage

      console.log(dataStudentsGroup)
      const stageGroups = dataStudentsGroup?.subGroupsByMomentStage.find(
        (p) => p.idProjectMomentStage == idMomentStep || p.idLessonMomentActivity == idMomentStep,
      )

      if (stageGroups) {
        stageGroups.subGroups = stageGroups.subGroups?.map((subGroup) => {
          return { ...subGroup, currentIndex: 0 }
        })
      }

      const step: StepData = {
        icon,
        title,
        description,
        idMomentStep,
        stageGroups,
        totalStudents: 10,
      }

      steps.push(step)
    }

    return steps
  }, [dataClass, dataStudentsGroup])

  const [steps, setSteps] = useState<StepData[]>(buildSteps)

  useEffect(() => {
    setSteps(buildSteps())
  }, [buildSteps])

  useEffect(() => {
    findAllSelectedStudents()
    findAllStudentsNotInAttendance()
  }, [steps])

  const getPagedGroup = (currentIndex: number, group: Group[]): Group[] => {
    const paged = group?.slice(currentIndex, currentIndex + maxLengthDisplay)
    return paged
  }

  const handlePreviousGroupPage = (steps: StepData[], stepIndex: number, subIndex: number): void => {
    const newSteps = [...steps]
    const currentStep = newSteps[stepIndex]
    const currentSubGroup = currentStep.stageGroups?.subGroups[subIndex]

    if (currentSubGroup.currentIndex > 0) {
      currentSubGroup.currentIndex--
    }

    setSteps(newSteps)
  }

  const handleNextGroupPage = (steps: StepData[], stepIndex: number, subIndex: number): void => {
    const newSteps = [...steps]
    const currentStep = steps[stepIndex]
    const currentSubGroupInNewSteps = currentStep.stageGroups?.subGroups[subIndex]

    currentSubGroupInNewSteps.currentIndex++

    setSteps(newSteps)
  }
  const truncateDescription = (description: string, maxLength: number) => {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + '...'
    }
    return description
  }

  const StudentsGroupContainer: React.FC<StudentsGroupContainerProps> = ({ sub, subIndex, stepIndex, step }) => {
    const [{ isOver, canDrop }, drop] = useDrop<DraggedItem>(() => ({
      accept: 'CARD',
      drop: (item) => {
        if (
          item?.object?.idProjectMomentStage !== step.idMomentStep ||
          item?.object?.inAttendance === false ||
          item.object.nuSubGroup !== sub.nuSubGroup
        ) {
          moveStudentsToDifferentStep(
            selectedStudents,
            item.object.idUserStudent,
            step.idMomentStep,
            sub.nuSubGroup || 1,
          )
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }))

    let backgroundColor = '#fff'
    let border = 'none'
    if (isOver && canDrop) {
      border = 'solid 2px #0095FF'
      backgroundColor = '#E8F6FF'
    } else if (canDrop) {
      backgroundColor = 'white'
      border = 'solid 2px #BDC3CF'
    }

    function moveStudentsToDifferentStep(
      selectedStudents: any[],
      idUserStudent: number,
      targetMomentStep: number,
      targetSubGroup: number,
    ) {
      let allMovedStudents = []

      selectedStudents.forEach((student) => {
        allMovedStudents.push({
          ...student,
          inAttendance: true,
          nuSubGroup: targetSubGroup,
          idMomentStep: targetMomentStep,
        })
      })

      const draggedStudent = selectedStudents.find((student) => student.idUserStudent === idUserStudent)
      if (!draggedStudent) {
        allMovedStudents.push({
          idUserStudent: idUserStudent,
          inAttendance: true,
          nuSubGroup: targetSubGroup,
          idMomentStep: targetMomentStep,
        })
      }
      sendUpdateRequest(allMovedStudents)
    }

    return (
      <S.StudentsGroupContainer ref={drop} showGroup={true}>
        <S.GroupIdentifier totalGroup={step.stageGroups?.subGroups.length > 1 ? 4 : sub?.group?.length}>
          Grupo {sub?.subGroupLetter ?? 'A'}
        </S.GroupIdentifier>

        <S.ListStudents style={{ backgroundColor, border }}>
          <S.PutStart>
            <S.Arrow
              onClick={() => handlePreviousGroupPage(steps, stepIndex, subIndex)}
              disabled={sub.currentIndex <= 0}
              enabled={sub.currentIndex > 0}
            >
              <UilArrowLeft size="20" />
            </S.Arrow>
          </S.PutStart>

          {getPagedGroup(sub?.currentIndex, sub?.group)?.map((student) => {
            return (
              <>
                <div
                  key={student?.idMomentGroup}
                  onMouseOver={() => setDraggedStudentId(student?.idStudent)}
                  onFocus={() => setDraggedStudentId(student?.idStudent)}
                  onClick={() => handleSelectStudent(student?.idStudent, step)}
                  role="button"
                  tabIndex={0}
                >
                  <CardStudentComponent
                    key={student?.idMomentGroup}
                    id={Number(student?.idStudent)}
                    photoUrl={student?.studentClass?.student?.txImagePath}
                    txName={student?.studentClass?.student?.txName}
                    nuSubGroup={sub?.nuSubGroup}
                    object={student}
                    students={selectedStudents}
                    length={2}
                    idBatch={draggedStudentId}
                  />
                  <CustomDragLayer
                    photoUrl={student?.studentClass?.student?.txImagePath}
                    txName={student?.studentClass?.student?.txName}
                    students={selectedStudents}
                  />
                </div>
              </>
            )
          })}

          <S.PutEnd>
            <S.Arrow
              onClick={() => handleNextGroupPage(steps, stepIndex, subIndex)}
              disabled={(sub?.group?.length ?? 0) - (sub?.currentIndex ?? 0) <= maxLengthDisplay}
              enabled={(sub?.group?.length ?? 0) - (sub?.currentIndex ?? 0) > maxLengthDisplay}
            >
              <UilArrowRight size="20" />
            </S.Arrow>
          </S.PutEnd>
        </S.ListStudents>
      </S.StudentsGroupContainer>
    )
  }

  const AbsentsGroupContainer = () => {
    const [{ isOver, canDrop }, drop] = useDrop(() => ({
      accept: 'CARD',
      drop: (item: any) => {
        moveStudentsToAbsent(
          selectedStudents,
          item?.object?.idUserStudent,
          item?.object?.idProjectMomentStage || item?.object?.idLessonMomentActivity,
          item?.object?.nuSubGroup || 1,
        )
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }))

    let backgroundColor = '#fff'
    let border = '1px dashed #7C8189'
    if (isOver && canDrop) {
      border = 'solid 2px #0095FF'
      backgroundColor = '#E8F6FF'
    } else if (canDrop) {
      backgroundColor = 'white'
      border = '2px dashed #BDC3CF'
    }

    function moveStudentsToAbsent(
      selectedStudents: any[],
      idUserStudent: number,
      targetMomentStep: number,
      targetSubGroup: number,
    ) {
      let allAbsentStudents = []

      selectedStudents.forEach((student) => {
        allAbsentStudents.push({
          ...student,
          inAttendance: false,
          nuSubGroup: targetSubGroup,
          idMomentStep: student.idProjectMomentStage || student.idLessonMomentActivity,
          isSelected: false,
        })
      })

      const draggedStudent = selectedStudents.find((student) => student?.idUserStudent === idUserStudent)
      if (!draggedStudent) {
        allAbsentStudents.push({
          idUserStudent: idUserStudent,
          inAttendance: false,
          nuSubGroup: targetSubGroup,
          idMomentStep: targetMomentStep,
          isSelected: false,
        })
      }

      sendUpdateRequest(allAbsentStudents)
    }

    return (
      <S.ContainerAbsentStudents ref={drop}>
        <S.TitleAbsentStudents>Ausentes</S.TitleAbsentStudents>

        <S.ContentAbsentStudents
          style={{ backgroundColor, border }}
          absentStudentsCount={studentsNotInAttendance.length}
        >
          {steps.some((step) =>
            step?.stageGroups?.subGroups.some((subGroup) => subGroup.group.some((student) => !student.inAttendance)),
          ) ? (
            <>
              {steps.map((step) =>
                step?.stageGroups?.subGroups.map((subGroup) =>
                  subGroup.group
                    .filter((student) => !student.inAttendance)
                    .map((student) => (
                      <div key={student.idStudent} onClick={() => handleSelectStudent(student.idStudent, step)}>
                        <AvatarStudentComponent
                          isSelected={student.isSelected}
                          id={student.idStudent}
                          txName={student.studentClass.student.txName}
                          group={selectedStudents}
                          photoUrl={student.studentClass.student.txImagePath}
                          object={student}
                        />
                      </div>
                    )),
                ),
              )}
            </>
          ) : (
            <S.TextAbsentStudents>Arraste alunos para cá</S.TextAbsentStudents>
          )}
        </S.ContentAbsentStudents>
      </S.ContainerAbsentStudents>
    )
  }

  const checkGroupSizes = () => {
    for (const step of steps) {
      for (const subGroup of step.stageGroups?.subGroups || []) {
        const groupSize = subGroup.group.filter((student) => student.inAttendance).length
        if (groupSize > 7 || groupSize < 3) {
          return 'warning'
        }
      }
    }
    return 'success'
  }
  return (
    <ModalComponent
      header={true}
      title={`Gerenciar ${totalStudents} alunos`}
      footer={true}
      open={openModal}
      actionCloseModal={actionCloseModal}
      onSubmit={checkGroupSizes() === 'warning' ? () => setShowPlanModal(true) : actionCloseModal}
      checkGroupSizes={checkGroupSizes()}
      showNotification={true}
      countStudents={countStudents}
      isManageStudentsModal
      handleDownloadStudentsGroupsPdf={() => handleDownload()}
      isStudentsGroupsPdfRequestLoading={isLoading}
    >
      <S.ContentModal>
        {isLoadingManageStudents && (
          <AlertAssessment
            style={{
              zIndex: 99999,
            }}
            iconMapping={{
              info: <CircularProgress color="inherit" size={16} />,
            }}
            severity={'info'}
          >
            Estamos processando o seu gerenciamento.
          </AlertAssessment>
        )}

        <S.TextDescription>Arraste um ou mais alunos para movê-los entre os grupos.</S.TextDescription>

        <S.General className="styled-scroll-s">
          {steps.map((step, stepIndex) => {
            const [openSteps, setOpenSteps] = useState(steps.map((_, index) => index))

            const handleToggle = (index: number) => {
              if (openSteps.includes(index)) {
                setOpenSteps(openSteps.filter((i) => i !== index))
              } else {
                setOpenSteps([...openSteps, index])
              }
            }

            const subGroups = step.stageGroups?.subGroups || []
            const totalGroup =
              subGroups?.reduce((acc, subGroup) => {
                const inAttendanceCount = subGroup.group.filter((student) => student.inAttendance).length
                return acc + inAttendanceCount
              }, 0) || 0

            const subGroupsCount = subGroups?.length

            return (
              <>
                <S.ContentStudentsPic>
                  <S.TitleLabelStudents>
                    <S.WrapperHeader>
                      {subGroupsCount === 1 && (totalGroup < 3 || totalGroup > 7) ? (
                        <S.IconAlert>
                          <UilExclamationTriangle />
                          {totalGroup}
                          {totalGroup === 1 ? ' aluno' : ' alunos'}
                        </S.IconAlert>
                      ) : (
                        <S.ChipTotal>
                          {totalGroup}
                          {totalGroup === 1 ? ' aluno' : ' alunos'}
                        </S.ChipTotal>
                      )}
                      <IconComponent code={step.icon} size="medium" fontWeight="normal" />
                      <S.TooltipLabel
                        title={step.description}
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#000',
                              '& .MuiTooltip-arrow': {
                                color: 'common.black',
                              },
                            },
                          },
                        }}
                      >
                        <div>
                          {step.title} - <b>{truncateDescription(step.description, 50)}</b>
                        </div>
                      </S.TooltipLabel>
                    </S.WrapperHeader>

                    <S.ArrowCollapse onClick={() => handleToggle(stepIndex)} enabled={true}>
                      {openSteps.includes(stepIndex) ? <UilArrowUp size="20" /> : <UilArrowDown size="20" />}
                    </S.ArrowCollapse>
                  </S.TitleLabelStudents>

                  <Collapse in={openSteps.includes(stepIndex)}>
                    {step.stageGroups && step.stageGroups.subGroups.length > 0 ? (
                      step.stageGroups.subGroups
                        .sort((a, b) => a.nuSubGroup - b.nuSubGroup)
                        .map((sub, subIndex) => {
                          const filteredGroup = {
                            ...sub,
                            group: sub.group.filter((student) => student.inAttendance),
                          }

                          return (
                            <StudentsGroupContainer
                              key={subIndex}
                              subIndex={subIndex}
                              sub={filteredGroup}
                              stepIndex={stepIndex}
                              step={step}
                              showGroup={true}
                            />
                          )
                        })
                    ) : (
                      <StudentsGroupContainer
                        key={stepIndex}
                        sub={[]}
                        subIndex={stepIndex}
                        stepIndex={stepIndex}
                        step={step}
                        showGroup={true}
                      />
                    )}
                  </Collapse>
                </S.ContentStudentsPic>
              </>
            )
          })}
          <AbsentsGroupContainer />
        </S.General>
        <ModalPlaneComponent
          actionCofirm={actionCloseModal}
          icon={<UilExclamationTriangle size={48} color="#FF8A00" />}
          titleLabel="Existem grupos com a quantidade de alunos diferente da ideal"
          open={showPlanModal}
          description={'A prática pedagógica sugere que os grupos de no mínimo 3 e no máximo 7 alunos.'}
          actionCloseModal={handleCloseModal}
          confirmCancelText="Salvar mesmo assim"
          isAlert={true}
        />
      </S.ContentModal>
    </ModalComponent>
  )
}
